import Button from './Button';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';

const HowToUseSection = () => {
  const router = useRouter();
  const language = router.locale as SupportedLanguages;
  const isSpanish = language === 'es';

  const handleMoreInfoClick = () => {
    toast.success(
      <div className="flex flex-col gap-2">
        <div className="text-sm">
          <strong className="font-bold">
            {isSpanish
              ? '¡Gracias por tu interés!'
              : 'Thank you for your interest!'}
          </strong>
          <p className="text-left">
            {isSpanish
              ? 'Estamos trabajando arduamente para mejorar nuestros servicios. ¡Mantente atento a futuras actualizaciones!'
              : 'We are working hard to improve our services. Stay tuned for future updates!'}
          </p>
        </div>
      </div>,
      {
        position: 'top-right',
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'light',
        icon: false,
        toastId: 'info-toast',
      }
    );
  };

  return (
    <div className="px-4 md:border-t md:pt-6 md:border-copper pt-4">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-4">
        <div
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(/monkey.png)`,
          }}
          className="w-full h-44 rounded-2xl flex flex-col justify-center text-white p-4"
        >
          <h3 className="how-to-use-section__step-te">
            <strong>{isSpanish ? 'Cómo usar' : 'How to use'}</strong> Tikipal
          </h3>
          <p className="w-36 text-sm pt-2">
            {isSpanish
              ? 'Compra en cualquier lugar las entradas para la fiesta'
              : 'Buy your tickets for the party anywhere'}
          </p>
          <div className="w-36 mt-4">
            <Link
              href="https://docs.google.com/forms/d/1IhfU0-msGDpscS64kZDLO6ftrOstWLeBDFNUe54KQNo/edit?ts=6748e810"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button size="small">
                {isSpanish ? 'Más información' : 'More info'}
              </Button>
            </Link>
          </div>
        </div>

        <div
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(/monkey2.png)`,
          }}
          className="w-full h-44 rounded-2xl flex flex-col justify-center text-white p-4"
        >
          <h3 className="how-to-use-section__step-te">
            <strong>{isSpanish ? 'Organiza' : 'Organize'}</strong>{' '}
            {isSpanish ? 'tu evento' : 'your event'}
          </h3>
          <p className="w-36 text-sm pt-2">
            {isSpanish
              ? 'Gestiona cumpleaños, eventos o conciertos'
              : 'Manage birthdays, events, or concerts'}
          </p>
          <div className="w-36 mt-4">
            <Link
              href="https://docs.google.com/forms/d/1702eMb4R8fOnQvhiWmj4W1omJyu69PSYpjdOJmlnJQs/edit?ts=6748dee6"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button size="small">
                {isSpanish ? 'Crear Evento' : 'Create Event'}
              </Button>
            </Link>
          </div>
        </div>

        <div
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url(/monkey3.png)`,
          }}
          className="w-full h-44 rounded-2xl flex flex-col justify-center text-white p-4"
        >
          <h3 className="how-to-use-section__step-te">
            <strong>{isSpanish ? 'Tiki' : 'Tiki'}</strong>
            {isSpanish ? 'aliados' : 'partners'}
          </h3>
          <p className="w-36 text-sm pt-2">
            {isSpanish
              ? 'Gestiona tus entradas y fideliza tus clientes'
              : 'Manage your tickets and build customer loyalty'}
          </p>
          <div className="w-36 mt-4">
            <Link
              href="https://docs.google.com/forms/d/1702eMb4R8fOnQvhiWmj4W1omJyu69PSYpjdOJmlnJQs/edit?ts=6748dee6"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button size="small">
                {isSpanish ? 'Registrar Aliado' : 'Register Partner'}
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowToUseSection;
